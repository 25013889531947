@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
body {
    background-color: #eee !important;
    font-family: "Nunito", sans-serif !important;
    color:#222;
    font-size: 14px;
    overflow-x: hidden;
}

*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
a {
    color:#222;
}

input::placeholder {
    font-size: 0.85rem;
}

textarea::placeholder {
    font-size: 0.85rem;
}

select{
    font-size: 14px !important;
    
}

select option{
    font-family: 'Nunito';

}



header {
    background:#fff;
}

.mp0 {
    margin:0;
    padding:0;
}

.mt-25 {
    margin-top:25px !important;
}

.mr-5 {
    margin-right:50px;
}

.mb-3 {
    margin-bottom:25px;
}

.card{
    border-radius: 0.55rem !important;
}

.nav-link {
    color: #222;
    width: auto !important;
}

.nav-link:focus, .nav-link:hover {
    color: #222;
}

.btn-liebherr {
    color: #000;
    background-color: #ffd000;
    border-color: #ffd000f4;
    text-transform: uppercase;
}

.btn-liebherr:hover {
    color: #000;
    background-color: #ffd000ea;
    border-color: #ffd000ea;
    text-transform: uppercase;
}
.newcomplaint {
    display: inline-block;
    margin-right: 50px;
  }

.width100 {
    width:100%;
}

.activeM {
    color: #000;
    background-color: #ffd000ea; 
    padding:8px;
    font-size: 14px;
    font-family: 'Nunito';
}

.complbread{
    background: aliceblue;
    
  }

.breadMain {
    background: #ffd000ea;
    padding: 15px;
}

.breadcrumbc{
    background: #ffd000ea;
    margin-bottom: 15px;
    padding: 5px 15px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0 0 4px #ddd;
}

.breadcrumb {
    margin-bottom:0;
} 

.breadcrumb li.active {
    background-color: #ffd000ea; 
    color:#222;
    padding:5px;
 }

.tab_box {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

#tabsMenu a {
    text-transform:uppercase;
    
}

#tabsMenu a.active {
    font-weight: 600;
}

.text-right {
    text-align: right;
  }
  .deletehelper img {
    width: 20%;
    color: red;
  }
  .edithelper img {
    width: 20%;
    color: blue;
  }

.pname {
    font-size: 14px;
    font-weight: 700;
}

#searchResult {
    /*display:none;*/
}




h3.mainheade{
    font-size: 16px;
    margin-bottom: 20px;
    background: #f3f3f3;
    padding: 10px;
    font-weight: 700;
}



.ticketBox {
    max-height: 400px;
    overflow: auto;
}

.bg-green {
    background-color: green;
    color: #fff;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.bg-green h2{
    font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}

.bg-warntick {
    background-color: #ffc107;
    color: #222;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.bg-sparetick h2{
    font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}

.bg-sparetick {
    background-color: bisque;
    color: #222;
    padding: 5px;
    border-radius: 3px;
    margin-bottom: 15px;
}

.bg-warntick h2{
    font-size: 17px;
  font-weight: 700;
  margin-bottom: 0;
}


.ticketSet {
    background-color: #f3f3f3;
    padding:5px;
    border-radius: 5px;
    margin-bottom:7px;
    border:1px solid #f3f3f3;
    cursor: pointer;
}

.ticketSetAlt {
    background-color: aliceblue;
    padding:5px;
    border-radius: 5px;
    margin-bottom:7px;
    border:1px solid #f3f3f3;
    cursor: pointer;
}

.ticketSet h4{
    font-size:16px;
    font-weight: 700;
}

.ticketSet h5{
    font-size:14px;
    font-weight: 500;
}

.ticketSetAlt h4{
    font-size:16px;
    font-weight: 700;
}

.ticketSetAlt h5{
    font-size:14px;
    font-weight: 500;
}

.input-class{
    margin-bottom: 15px !important; 
    font-size: 18px !important; 
}

.table-css{
    margin-top: 20px !important;
  table-layout: fixed !important;
}


.input-field {
    margin-bottom: 8px !important; /* Space below the element */
    font-size: 14px !important; /* Font size */
    font-family: 'Nunito', sans-serif !important; /* Font family */
  }

  .btn-liebherr {
    /* margin-top: 15px !important; Space above the element */
    background-color: rgba(255, 208, 0, 0.917) !important; /* Background color with opacity */
  }

  .dropdown-label{
    margin-bottom: 0.5rem; /* Space below the element */
    display: inline-block; /* Element behaves like an inline element but maintains block properties */
    font-family: 'Nunito', sans-serif; /* Font family */
    font-size: 14px; /* Font size */
    padding-top: 0; /* No padding above */
    padding-bottom: 0; /* No padding below */
  }

  .dropdown-select{
  padding-right: 30px !important; /* Padding on the right */
  padding-left: 8px !important; /* Padding on the left */
  background-color: #fff !important; /* Background color */
  border-radius: 4px !important; /* Rounded corners */
  font-family: 'Nunito', sans-serif !important; /* Font family */
  font-size: 14px !important; /* Font size */
  margin-bottom: 11px !important; /* Space below the element */
  }